<template>
    <div style="text-align: left;">
		<Table :columns="ContributionLabel" :data="ContributionData" :show-header="true" style="width: 100%;">
			<template slot-scope="{ row }" slot="name">
				<a>{{row.name}}</a>
			</template>
			<template slot-scope="{ row }" slot="percentage">
				<Progress :percent="row.percentage" :stroke-color="['#0dc9fc', '#ff8102']" />
			</template>
		</Table>
    </div>
</template>

<script>
    export default {
        data(){
            return{
				ContributionLabel: [],
				ContributionData: [],
            }
        },
        methods: {
           fakeInit(){
			   this.ContributionLabel = [
			   	{
			   		title: 'Name',
			   		slot: 'name',
					width: 160
			   	},
				{
					title: 'Functions completed',
					key: 'functions'
				},
			   	{
			   		title: 'Ownership shares',
			   		key: 'points',
			   		width: 115
			   	},
				{
					title: 'Shares ratio',
					slot: 'percentage'
				},
			   ];
			   this.ContributionData = [
			   	{
			   		name: 'John Brown',
			   		percentage: 27,
					points: 2700,
					functions: 'Software libraries, File systems, Web sites'
			   	},
			   	{
			   		name: 'Jim Green',
			   		percentage: 20,
					points: 2000,
					functions: 'Human factors, Educational products'
			   	},
			   	{
			   		name: 'Joe Black',
			   		percentage: 14,
					points: 1400,
					functions: 'Software tools'
			   	},
			   	{
			   		name: 'Jon Snow',
			   		percentage: 11,
					points: 1100,
					functions: 'Visual Understanding Environment'
			   	},
			   	{
			   		name: 'icarusion',
			   		percentage: 8,
					points: 800,
					functions: 'Tufts Academic Technology, teaching'
			   	},
			   	{
			   		name: 'SergioCrisostomo',
			   		percentage: 6,
					points: 600,
					functions: 'FEDORA-based digital repositories'
			   	},
			   	{
			   		name: 'NatJNP',
			   		percentage: 6,
					points: 600,
					functions: 'OKI-compliant software bridge'
			   	},
			   	{
			   		name: 'Snailclimb',
			   		percentage: 4,
					points: 400,
					functions: 'learning'
			   	},
			   	{
			   		name: 'jingsam',
			   		percentage: 3,
					points: 300,
					functions: 'VUE project'
			   	},
			   	{
			   		name: 'Fpelq',
			   		percentage: 3,
					points: 300,
					functions: 'File systems'
			   	}
			   ];
		   },
		   init(){
				this.ContributionData = [];
				this.ContributionLabel = [
					{
						title: 'Name',
						slot: 'name',
						width: 160
					},
					{
						title: 'Commits',
						key: 'commits',
						width: 160
					},
					{
						title: 'Lines',
						key: 'lines',
						width: 160
					},
					{
						title: 'Ownership shares',
						key: 'points',
						width: 115
					},
					{
						title: 'Shares ratio',
						slot: 'percentage'
					},
				];
				var projectId = this.$route.query.id;
				var Tself = this;
				this.$axios.get('/contributionStatisitc/getAllCodeContributionStatisitc?projectId='+projectId)
				.then(function (response) {
					var items = response.data.data.items;
					if(items.length == 1 && items[0].commits == 0){
						Tself.ContributionData = [];
						return;
					}
					for(var i = 0; i<items.length; i++){
						Tself.ContributionData.push({
							name: items[i].userName,
							commits: items[i].commits,
							lines: items[i].line,
							points: items[i].sharePoint.toFixed(2),
							percentage: (items[i].shareRatio*100).toFixed(2)
						})
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		   }
        },
        mounted() {
			this.init();
			// this.fakeInit()
        }
    }
</script>

<style scoped>

</style>
